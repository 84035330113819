/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import bg from '../images/festival/bg.jpg';
import StartingSection from '../components/festival/StartingSection';
import Spinner from '../components/layout/Spinner';
import CMSSection from '../components/festival/CMSSection';
import TileDrawer from '../components/festival/TileDrawer';
import Sponsors from '../components/festival/Sponsors';

const Festival = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState();
  const [activeTile, setActiveTile] = useState();
  const [isBackdropActive, setIsBackdropActive] = useState(false);

  const [fetchData] = useLazyQuery(GET_SUBPAGE_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const configs = [...data.festivalConfigs.nodes];
      const latestConfig = configs.reduce((prev, current) => {
        return new Date(prev.date) > new Date(current.date) ? prev : current;
      });
      setConfig(latestConfig.details);
      setIsLoading(false);
    },
  });

  const spinnerWrapper = (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        zIndex: 9999,
        position: 'relative',
      }}
    >
      <Spinner />
    </div>
  );

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const handleTileClick = (tile) => {
    if (tile.description) {
      setIsBackdropActive(true);
      setActiveTile(tile);
    }
  };

  const handleDrawerClose = () => {
    setIsBackdropActive(false);
    setActiveTile(undefined);
  };

  return (
    <Layout isFestival>
      <SEO title="Festiwal" />
      <div className="festival">
        <img src={bg} alt="background" />
        <div className="color-layer" />
        {isLoading || !config ? (
          spinnerWrapper
        ) : (
          <>
            <StartingSection details={config.startingSection} />
            {config.sections.map((s, i) => (
              <CMSSection
                key={s.section.header}
                handleTileClick={(tile) => handleTileClick(tile)}
                isOdd={i % 2 === 0}
                animationDirection={i % 2 === 0 ? 'left' : 'right'}
                details={s.section}
              />
            ))}
            <TileDrawer handleDrawerClose={handleDrawerClose} tile={activeTile} isActive={isBackdropActive} />
            <Sponsors details={config.sponsors} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Festival;

const GET_SUBPAGE_DATA = gql`
  query GetSubpageData {
    festivalConfigs {
      nodes {
        details {
          startingSection {
            colorHeader
            header
            description
            date
            previousEdition {
              description
              fieldGroupName
              link
            }
          }
          sections {
            section {
              colorHeader
              header
              description
              tiles {
                tile {
                  name
                  description
                  date
                  photo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          sponsors {
            caption
            background {
              mediaItemUrl
            }
          }
        }
        date
      }
    }
  }
`;
