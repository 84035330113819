import React from 'react';

const Sponsors = ({ details }) => {
  return (
    <div className="sponsors">
      <p>{details.caption}</p>
      {details.background && <img alt="Sponsorzy" src={details.background.mediaItemUrl} />}
    </div>
  );
};

export default Sponsors;
